import React, { useState } from "react";
import { Channel, ChannelSettings } from "sendbird-uikit";

import "./Chat.css";
import "sendbird-uikit/dist/index.css";
import Avatar from "./common/Avatar";

function validURL(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

const getMessageText = (message) => {
  const text = message.message;
  return (
    <span className="message-text">
      {validURL(text) ? (
        <a href={text} target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      ) : (
        text
      )}
    </span>
  );
};

const renderCustomMessage = (message, userId) => {
  if (message.messageType === "file") return;
  const time = new Date(message.createdAt).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
  const messageContent = (
    <div className="message-ballon">
      {getMessageText(message)}
      <br />
      <span className="time">{time}</span>
    </div>
  );
  if (message.customType === "call-status") {
    return () => <div className="call-status">{messageContent}</div>;
  }
  if (!message?.sender?.userId || message?.sender?.userId === userId) {
    return () => (
      <div className="message outgoing-message">{messageContent}</div>
    );
  }
  return () => <div className="message incoming-message">{messageContent}</div>;
};

const Chat = ({ channelUrl, userId, partner }) => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const focusInput = (text) => {
    const inputElement = document.getElementsByName(
      "sendbird-message-input"
    )[0];
    inputElement.focus();

    return text;
  };

  return (
    <>
      <Channel
        channelUrl={channelUrl}
        onChatHeaderActionClick={() => setIsSettingsOpen(!isSettingsOpen)}
        onBeforeSendUserMessage={focusInput}
        renderCustomMessage={(message) => renderCustomMessage(message, userId)}
        renderChatHeader={() => <ChatHeader partner={partner} />}
      />
      {isSettingsOpen && (
        <ChannelSettings
          channelUrl={channelUrl}
          onCloseClick={() => setIsSettingsOpen(false)}
        />
      )}
    </>
  );
};

const ChatHeader = ({ partner }) => {
  const getStatusMessage = () => {
    if (partner?.isTyping) return "Typing...";
    return partner?.connectionStatus || "Offline";
  };
  return (
    <div className="chat-header">
      <div className="chat-avatar-wrapper">
        <Avatar className="chat-avatar" alt="partner img" user={partner} />
      </div>
      <div>
        <div className="bold">{partner?.nickname}</div>
        <div className="small-text">{getStatusMessage()}</div>
      </div>
    </div>
  );
};

export default Chat;
