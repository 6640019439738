import React, { useEffect } from "react";
import Waves from "../assets/Waves.png";
import Avatar from "../common/Avatar";

import "./common.css";

const BaseRinging = ({ callee, text, children, hideWaves }) => {
  const init = () => {
    document.activeElement.blur();
  };

  useEffect(init, []);
  return (
    <div className="fullScreenPage ringing center">
      <div className="calling-header">
        {callee.nickname} <div>{text}</div>
      </div>
      <div>
        <Avatar
          user={callee}
          className="incoming-avatar center-pic"
          alt="callee img"
        />
        {!hideWaves && <img className="center-pic" src={Waves} alt="wave" />}
      </div>
      {children}
    </div>
  );
};

export default BaseRinging;
