import React, { useEffect, useState } from "react";
import useMinimize from "../hooks/useMinimize";
import BaseVideo from "./BaseVideo";

import "./common.css";

const RemoteVideo = ({ user, activeCall, id }) => {
  const [isVideoActive, setIsVideoActive] = useState();
  const { isMinimized } = useMinimize();

  const getWrapperClasses = () => {
    if (isMinimized) return "video-wrapper minimized";
    return "video-wrapper";
  };

  useEffect(() => {
    if (activeCall) {
      setIsVideoActive(activeCall?.isRemoteVideoEnabled);
      activeCall.onRemoteVideoSettingsChanged = (call) => {
        setIsVideoActive(call?.isRemoteVideoEnabled);
      };
    }
  }, [activeCall]);

  return (
    <div className={getWrapperClasses()}>
      <BaseVideo isVideoActive={isVideoActive} user={user} id={id} />
    </div>
  );
};
export default RemoteVideo;
