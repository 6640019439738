import { useEffect, useState } from "react";

export const useChannelInfo = (sdk, channelUrl) => {
  const [memo, setMemo] = useState();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const getChannelInfo = () => {
    setIsLoading(true);
    if (memo) return memo;

    try {
      sdk.GroupChannel.getChannel(channelUrl, (channel, error) => {
        if (error) {
          console.log("not in group", error);
          setError("not in group");
        } else {
          setMemo(channel);
        }
        setIsLoading(false);
      });
    } catch (e) {
      console.log("fail to get cannel info", e);
      setError("error");
    }
  };

  useEffect(() => {
    if (sdk && sdk.GroupChannel && channelUrl) getChannelInfo();
  }, [sdk, channelUrl]); // eslint-disable-line react-hooks/exhaustive-deps

  return [memo, error, isLoading];
};
