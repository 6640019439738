import React from "react";
import "./ImgButton.css";

const ImgButton = ({ icon, onClick, alt, text, hidden }) => {
  return (
    <div className={`btn ${hidden ? "hidden" : ""}`}>
      <img className="btn-img" src={icon} onClick={onClick} alt={alt} />
      <span className="btn-text small-text">{text}</span>
    </div>
  );
};

export default ImgButton;
