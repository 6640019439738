import React from "react";
import useMinimize from "../hooks/useMinimize";

import "./common.css";
import LocalVideo from "./LocalVideo";
import RemoteVideo from "./RemoteVideo";

const ActiveVideoCall = ({ me, callee, activeCall }) => {
  const { isMinimized, setIsMinimized } = useMinimize();
  return (
    <div onClick={() => isMinimized && setIsMinimized(false)}>
      <LocalVideo user={me} id="local-video" />
      <RemoteVideo user={callee} id="remote-video" activeCall={activeCall} />
    </div>
  );
};

export default ActiveVideoCall;
