import HumanizeDuration from 'humanize-duration';
import React, { useState } from 'react';
import SendBirdCall from 'sendbird-calls';
import 'sendbird-uikit/dist/index.css';
import useMinimize from '../hooks/useMinimize';
import ActiveCallButtons from './ActiveCallButtons';
import ActivePhoneCall from './ActivePhoneCall';
import ActiveVideoCall from './ActiveVideoCall';
import CallButtons from './CallButtons';
import './common.css';
import { InitCalls } from './InitCalls';
import Ringing from './Ringing';

const baseURL = 'https://s3.amazonaws.com/public.getlaguna.com/mp3/';

const Calls = ({ appId, me, callee, showCallButtons, sendMessage }) => {
  const [activeCall, setActiveCall] = useState();
  const [isRinging, setIsRinging] = useState(false);
  const [dialingTone] = useState(new Audio(baseURL + 'Dialing.mp3'));
  const [ringingTone] = useState(new Audio(baseURL + 'Ringing.mp3'));
  const { isMinimized, setIsMinimized } = useMinimize();

  const userId = me.userId;

  InitCalls(appId, userId, (call) => {
    setCall(call);
    setIsRinging(true);
    ringingTone.loop = true;
    ringingTone.play();
  });

  const accept = () => {
    const localMediaView = document.getElementById('local-video');
    const remoteMediaView = document.getElementById('remote-video');
    const acceptParams = {
      callOption: {
        localMediaView,
        remoteMediaView,
        audioEnabled: true,
        videoEnabled: true,
      },
    };

    activeCall.accept(acceptParams);
    setIsRinging(false);
  };

  const cleanup = () => {
    setCall(null);
    setIsRinging(false);
    setIsMinimized(false);
    dialingTone.pause();
    ringingTone.pause();
  };

  const hangup = () => {
    activeCall.end();
  };

  const getDialParams = (isVideoCall) => ({
    userId: callee.userId,
    isVideoCall,
    callOption: {
      localMediaView: document.getElementById('local-video'),
      remoteMediaView: document.getElementById('remote-video'),
      audioEnabled: true,
      videoEnabled: isVideoCall,
    },
  });

  const dial = (isVideoCall) => {
    SendBirdCall.dial(getDialParams(isVideoCall), (call, error) => {
      if (error) console.log(error);
      else {
        dialingTone.loop = true;
        dialingTone.play();
        setCall(call);
      }
    });
  };

  const setCall = (call) => {
    if (call) eventRegister(call);
    setActiveCall(call);
  };

  const eventRegister = (call) => {
    call.onEnded = (call) => {
      if (call.isCaller) {
        const message = `call ${call.endResult} ${
          call?.callLog?.duration > 0
            ? HumanizeDuration(call.callLog.duration, {
                units: ['m', 's'],
                round: true,
                maxDecimalPoints: 2,
              })
            : ''
        }`;
        sendMessage(message, 'call-status');
      }
      cleanup();
    };

    call.onConnected = () => {
      document.getElementById('local-video').play();
      dialingTone.pause();
      ringingTone.pause();
    };

    call.onRemoteAudioSettingsChanged = () => {
      // console.log(call, "onRemoteAudioSettingsChanged");
    };
  };

  const isVideoVisible = () => {
    return !!activeCall && !isRinging && activeCall?.isVideoCall;
  };

  const renderCall = () => {
    if (isRinging)
      return (
        <Ringing
          isVideoCall={activeCall?.isVideoCall}
          onAccept={accept}
          onHangup={hangup}
          callee={callee}
        />
      );
    if (activeCall?.isVideoCall === false && !isMinimized)
      return <ActivePhoneCall activeCall={activeCall} callee={callee} />;

    return null;
  };

  const showBackToCallButton = () =>
    activeCall?.isVideoCall === false && isMinimized;

  return (
    <>
      <CallButtons
        onVideoCall={
          !showBackToCallButton() && showCallButtons ? () => dial(true) : null
        }
        onCall={
          !showBackToCallButton() & showCallButtons ? () => dial(false) : null
        }
        backToCall={showBackToCallButton() ? () => setIsMinimized(false) : null}
      />

      {renderCall()}
      <div className={isVideoVisible() ? 'visible' : 'hidden'}>
        <ActiveVideoCall me={me} callee={callee} activeCall={activeCall} />
        {!isMinimized && <ActiveCallButtons activeCall={activeCall} />}
      </div>
    </>
  );
};
export default Calls;
