import React, { useEffect, useState } from "react";
import Chat from "./Chat";

import "sendbird-uikit/dist/index.css";
import "./Home.css";
import { useChannelInfo } from "./common/useChannelInfo";
import Calls from "./calls/Calls";
import Standby from "./Standby";
import Expired from "./assets/expired.svg";
import lagunaLoader from "./assets/lagunaLoader.svg";
import team from "./assets/team.png";

import Error from "./Error";
import { useChatEvents } from "./common/useChatEvents";
import { isSafari } from "./common/utils";
import CallProvider from "./context/CallContext";

const Home = ({ sdk, appId, userId, channelUrl, sendMessage }) => {
  const [channelInfo, error, isLoading] = useChannelInfo(sdk, channelUrl);
  const [isTyping, messageReceived, me, partner] = useChatEvents(
    sdk,
    channelInfo
  );
  const [showStandby, setShowStandby] = useState(true);
  const [isSafariUsed, setIsSafariUsed] = useState(isSafari());
  const myRole = me?.metaData?.role;

  useEffect(() => {
    if (!isLoading && !error) setShowStandby(!channelInfo.lastMessage);
  }, [isLoading, channelInfo, channelUrl]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (messageReceived && setShowStandby) setShowStandby(false);
  }, [messageReceived]); // eslint-disable-line react-hooks/exhaustive-deps

  if (error || (channelInfo?.isFrozen && myRole !== "coach"))
    return (
      <Error
        img={Expired}
        alt="expired"
        text="This appointment isn't active anymore"
      />
    );

  if (isSafariUsed)
    return (
      <div className="fullScreenPage center">
        <img src={team} alt="team" width="300px" />
        <div
          className="start-session-button"
          onClick={() => setIsSafariUsed(false)}
        >
          Start my session
        </div>
      </div>
    );

  if (isLoading || !me)
    return (
      <div className="fullScreenPage center">
        <img src={lagunaLoader} alt="loader" width="20%" />
        <span>Loading...</span>
      </div>
    );

  const _send = async (message, type) => {
    const params = new sdk.UserMessageParams();
    params.message = message;
    params.customType = type;
    sendMessage(channelUrl, params);
  };

  return (
    <>
      {showStandby && myRole !== "coach" && (
        <Standby callee={partner} user={me.nickname} />
      )}
      <CallProvider>
        <Calls
          appId={appId}
          me={me}
          callee={partner}
          sendMessage={_send}
          showCallButtons={myRole === "coach" && !channelInfo?.isFrozen}
        />
      </CallProvider>
      <Chat
        userId={userId}
        channelUrl={channelUrl}
        partner={{ ...partner, isTyping }}
      />
    </>
  );
};
export default React.memo(Home);
