import React from "react";
import CallIcon from "../assets/call.svg";
import VideoIcon from "../assets/video.svg";

import "./common.css";

const CallButtons = ({ onVideoCall, onCall, backToCall }) => {
  return (
    <div className="call-buttons">
      {onCall && (
        <img
          className="action-btn"
          src={CallIcon}
          onClick={onCall}
          alt="call"
        />
      )}
      {onVideoCall && (
        <img
          className="action-btn video-action-btn"
          src={VideoIcon}
          onClick={onVideoCall}
          alt="video"
        />
      )}
      {backToCall && (
        <img
          className="action-btn"
          src={CallIcon}
          onClick={backToCall}
          alt="back to call"
          title="back to call"
        />
      )}
    </div>
  );
};

export default CallButtons;
