import React from "react";
import "./Standby.css";

const Standby = ({ user, callee }) => {
  return (
    <div className="fullScreenPage center">
      <div className="row">
        <img className="avatar" src={callee.profileUrl} alt="img" />
      </div>
      <h1>Hi {user}</h1>
      <div className="wait-text">
        {callee.nickname} will join you here in a moment and meeting will start
        automatically.
      </div>
    </div>
  );
};

export default Standby;
