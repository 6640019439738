import { useEffect } from "react";
import SendBirdCall from "sendbird-calls";

const LISTENER_ID = "listener-id-call";

export function InitCalls(appId, userId, onRinging) {
  const onLogin = async (user, error) => {
    if (error) {
      console.log("fail to login");
    } else {
      await SendBirdCall.connectWebSocket();
      SendBirdCall.addListener(LISTENER_ID, {
        onRinging: (call) => onRinging(call, user),
      });
    }
  };

  const init = async () => {
    SendBirdCall.init(appId);
    const authOption = { userId };
    SendBirdCall.authenticate(authOption, onLogin);
  };

  useEffect(() => {
    if (appId && userId) init();
    return () => {
      SendBirdCall.removeListener(LISTENER_ID);
    };
  }, [appId, userId]); // eslint-disable-line react-hooks/exhaustive-deps
}
