import { useEffect, useState } from "react";

const UNIQUE_HANDLER_ID = "UNIQUE_HANDLER_ID";

const getPartnerInfoFromChannel = (channelInfo, myId) => {
  return channelInfo.members.filter((member) => member.userId !== myId)[0];
};

const getMyInfoFromChannel = (channelInfo, myId) => {
  return channelInfo.memberMap[myId];
};

export const useChatEvents = (sdk, channelInfo) => {
  const [isTyping, setIsTyping] = useState(false);
  const [messageReceived, setMessageReceived] = useState(false);
  const [me, setMe] = useState();
  const [partner, setPartner] = useState();

  const refreshPartnerStatus = () => {
    channelInfo.refresh((channel) => {
      const partnerInfo = getPartnerInfoFromChannel(
        channel,
        sdk.getCurrentUserId()
      );
      setPartner(partnerInfo);
    });
  };

  const updatePartnerStatusIfNotOnline = () => {
    if (partner?.connectionStatus !== sdk.User.ONLINE) {
      refreshPartnerStatus();
    }
  };

  const init = () => {
    const channelHandler = new sdk.ChannelHandler();

    channelHandler.onReadReceiptUpdated = (channel) => {
      if (channel?.url === channelInfo.url) {
        updatePartnerStatusIfNotOnline();
      }
    };

    channelHandler.onMessageReceived = (channel, message) => {
      if (channel?.url === channelInfo.url) {
        setMessageReceived(message);
      }
    };

    channelHandler.onTypingStatusUpdated = (channelInfo) => {
      updatePartnerStatusIfNotOnline();
      setIsTyping(channelInfo.isTyping());
    };

    setMe(getMyInfoFromChannel(channelInfo, sdk.getCurrentUserId()));
    setPartner(getPartnerInfoFromChannel(channelInfo, sdk.getCurrentUserId()));

    sdk.addChannelHandler(UNIQUE_HANDLER_ID, channelHandler);
  };

  useEffect(() => {
    if (sdk && channelInfo) init();

    return () => {
      if (sdk?.removeChannelHandler) {
        sdk.removeChannelHandler(UNIQUE_HANDLER_ID);
      }
    };
  }, [sdk, channelInfo]); // eslint-disable-line react-hooks/exhaustive-deps

  return [isTyping, messageReceived, me, partner];
};
