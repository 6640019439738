import React, { useEffect } from "react";
import HangupIcon from "../assets/hangup.svg";
import AnswerCall from "../assets/AnswerCall.svg";
import AnswerVideo from "../assets/AnswerVideo.svg";
import ImgButton from "../common/ImgButton";
import BaseRinging from "./BaseRinging";
import "./common.css";

const Ringing = ({ isVideoCall, callee, onAccept, onHangup }) => {
  const init = () => {
    document.activeElement.blur();
  };
  useEffect(init, []);
  return (
    <BaseRinging callee={callee} text="calling...">
      <div className="buttons-row">
        <ImgButton
          icon={HangupIcon}
          alt="decline call"
          onClick={onHangup}
          text="decline"
        />
        <ImgButton
          icon={isVideoCall ? AnswerVideo : AnswerCall}
          alt="answer call"
          onClick={onAccept}
          text="answer"
        />
      </div>
    </BaseRinging>
  );
};

export default Ringing;
