const ua = window.navigator.userAgent;
const isIOS = () => {
  return !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
};

const isSafari = () =>
  /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const isIOSButNotSafari = () => {
  var webkit = !!ua.match(/WebKit/i);
  var iOSSafari =
    isIOS() && webkit && !ua.match(/CriOS/i) && !ua.match(/OPiOS/i);
  return isIOS() && !iOSSafari;
};

const getURLParams = () => {
  try {
    var search = document.location.search.substring(1);
    return JSON.parse(
      '{"' +
        decodeURI(search)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"') +
        '"}'
    );
  } catch (e) {
    console.log("invalid url");
  }
  return {};
};
const setWindowFullHeight = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
};
export {
  isIOS,
  isIOSButNotSafari,
  getURLParams,
  isSafari,
  setWindowFullHeight,
};
