import React from "react";

import "./common.css";
import Avatar from "../common/Avatar";

function BaseVideo({ user, isVideoActive, id, muted = false }) {
  return (
    <>
      <Avatar
        className="incoming-avatar center-pic"
        alt={user?.nickname}
        style={
          isVideoActive
            ? { display: "none" }
            : { display: "block", zIndex: 2, background: "white" }
        }
        user={user}
      />

      <video id={id} className="video" muted={muted} autoPlay playsInline />
    </>
  );
}
export default BaseVideo;
