import React, { useEffect, useState } from "react";
import HangupIcon from "../assets/hangup.svg";
import MuteIcon from "../assets/mute.svg";
import UnMuteIcon from "../assets/unmute.svg";
import StopCam from "../assets/stopCam.svg";
import StartCam from "../assets/startCam.svg";
import ShowChatIcon from "../assets/showChat.svg";

import "./common.css";
import "sendbird-uikit/dist/index.css";
import ImgButton from "../common/ImgButton";
import useCallExtraInfo from "../hooks/useCallExtraInfo";
import useMinimize from "../hooks/useMinimize";

const ActiveCallButtons = ({ activeCall }) => {
  //TODO: remove local mute state and use activeCall?.isLocalAudioEnabled instead
  const [muted, setMuted] = useState(false);
  const { appendCallExtraInfo } = useCallExtraInfo();
  const { setIsMinimized } = useMinimize();

  const hangup = () => {
    activeCall.end();
  };

  const mute = () => {
    muted ? activeCall.unmuteMicrophone() : activeCall.muteMicrophone();
    setMuted(!muted);
  };

  const setIsLocalVideoEnabled = (state) => {
    appendCallExtraInfo({ isLocalVideoEnabled: state });
  };

  const toggleLocalVideo = () => {
    activeCall?.isLocalVideoEnabled
      ? activeCall.stopVideo()
      : activeCall.startVideo();
    setIsLocalVideoEnabled(!activeCall?.isLocalVideoEnabled);
  };

  useEffect(() => {
    if (activeCall) setIsLocalVideoEnabled(activeCall?.isLocalVideoEnabled);
  }, [activeCall?.isLocalVideoEnabled]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="minimize-button small-text">
        <ImgButton
          icon={ShowChatIcon}
          alt="show chat"
          onClick={() => setIsMinimized(true)}
          text=""
        />
      </div>
      <div className="buttons">
        <ImgButton
          icon={muted ? MuteIcon : UnMuteIcon}
          alt="mute call"
          onClick={mute}
          text={muted ? "Unmute" : "Mute"}
        />
        <ImgButton
          icon={HangupIcon}
          alt="end call"
          onClick={hangup}
          text="End Call"
        />
        <ImgButton
          icon={activeCall?.isLocalVideoEnabled ? StartCam : StopCam}
          alt="stop video"
          onClick={toggleLocalVideo}
          text={activeCall?.isLocalVideoEnabled ? "Stop" : "Start"}
          hidden={!activeCall?.isVideoCall}
        />
      </div>
    </>
  );
};
export default ActiveCallButtons;
