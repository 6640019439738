import React, { useState, useEffect } from 'react';
import {
  SendBirdProvider,
  sendBirdSelectors,
  withSendBird,
} from 'sendbird-uikit';
import './App.css';
import notFound from './assets/404.svg';
import Error from './Error';
import Home from './Home';
import Expired from './assets/expired.svg';
import Clipboard from 'clipboard';
import { isIOSButNotSafari, getURLParams } from './common/utils';

const APP_ID = process.env.REACT_APP_CHAT_APP_ID;

function App() {
  const [userId, setUserId] = useState();
  const [saveText, setSaveText] = useState('Copy Link');
  const [appId] = useState(APP_ID);
  const [channelUrl, setChannelUrl] = useState('');

  const init = () => {
    var { uid, mid } = getURLParams();
    setUserId(uid);
    setChannelUrl(mid);
  };

  useEffect(() => {
    init();
  }, []);

  if (isIOSButNotSafari()) {
    var url = document.location.href;
    const clipboard = new Clipboard('#copy-link', {
      text: function () {
        return url;
      },
    });
    clipboard.on('success', () => setSaveText('Copied'));
    clipboard.on('error', () => setSaveText('Error, click again'));
    return (
      <Error
        img={Expired}
        alt='browser not supported'
        text='Currently we don’t support Chrome on iOS. Please click below to copy the link and paste in Safari'>
        <button id='copy-link'>{saveText}</button>
      </Error>
    );
  }
  if (!userId || !channelUrl)
    return <Error img={notFound} alt='not found' text='Invalid URL' />;
  return (
    <div className='App'>
      <SendBirdProvider appId={appId} userId={userId}>
        <HomeWithSDK appId={appId} userId={userId} channelUrl={channelUrl} />
      </SendBirdProvider>
    </div>
  );
}

// add the sendbird sdk to home component
const HomeWithSDK = withSendBird(Home, (state) => {
  const sdk = sendBirdSelectors.getSdk(state);
  const sendMessage = sendBirdSelectors.getSendUserMessage(state);

  return { sdk, sendMessage };
});

export default App;
