import React from "react";
import ActiveCallButtons from "./ActiveCallButtons";
import BaseRinging from "./BaseRinging";
import "./common.css";

const ActivePhoneCall = ({ activeCall, callee }) => {
  return (
    <BaseRinging callee={callee} text="" hideWaves>
      <ActiveCallButtons activeCall={activeCall} />
    </BaseRinging>
  );
};

export default ActivePhoneCall;
