import React, { useState } from "react";

export const CallContext = React.createContext({});

export default function CallProvider({ children }) {
  const [callExtraInfo, setCallExtraInfo] = useState({});
  return (
    <CallContext.Provider value={{ callExtraInfo, setCallExtraInfo }}>
      {children}
    </CallContext.Provider>
  );
}
