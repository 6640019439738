import React from "react";

const Error = ({ img, alt, text, children }) => {
  return (
    <div className="fullScreenPage center">
      <img src={img} alt={alt} />
      <span style={{ padding: "1em 4em" }}>{text}</span>
      {children}
    </div>
  );
};

export default Error;
